import React from 'react';
import { Client } from '../lib/dataTypes';
import '../theme/components/clientAvatar.css';
import { IonAvatar, IonLabel } from '@ionic/react';
import { Spinner } from './index';
import { Home } from 'react-ionicons';

interface Props {
    data?: Client | null;
    loadingClient?: boolean;
}

const ClientAvatar = ({ data, loadingClient }: Props) => {
    console.log(data);
    return (
        <div id="client-avatar-container">
            <IonAvatar>
                <Home color="grey" />
            </IonAvatar>
            <div className="avatar">
                <IonLabel id="client-avatar-label">{loadingClient ? <Spinner size={18} /> : data?.name}</IonLabel>
            </div>
        </div>
    );
};

export default ClientAvatar;
