import React from 'react';
import { IonList } from '@ionic/react';
import { AdminSettingsListItem } from './index';
import { AdminSettingsRoute } from '../lib/dataTypes';

interface Props {
    routes: AdminSettingsRoute[];
    onRouteSelected: (url: string) => void;
}

const AdminSettingsList = ({ routes, onRouteSelected }: Props) => {
    return (
        <IonList>
            {routes.map((route: AdminSettingsRoute) => {
                return <AdminSettingsListItem key={route.url} route={route} onRouteSelected={onRouteSelected} />;
            })}
        </IonList>
    );
};

export default AdminSettingsList;
