import React from 'react';
import '../theme/components/titleBar.css';
import ENTIO from '../../src/images/ENTIO.png';
import { ArrowBackOutline } from 'react-ionicons';
import { IonHeader, IonToolbar, IonTitle, IonIcon } from '@ionic/react';
import { settingsSharp } from 'ionicons/icons';

interface Props {
    isBackMode?: boolean;
    isPrincipalMode?: boolean;
    isHomeMode?: boolean;
    arrowBackClick?: () => void;
    onSettingsClick?: () => void;
}

const TitleBar = ({ isPrincipalMode, isBackMode, isHomeMode, arrowBackClick, onSettingsClick }: Props) => {
    return (
        <IonHeader mode="md">
            <IonToolbar id="header">
                {isPrincipalMode && (
                    <>
                        <img alt="etbill-logo" id="nav-image" src={ENTIO} />
                        <IonTitle id="nav-title" size="large">
                            etbill
                        </IonTitle>
                    </>
                )}

                {isBackMode && (
                    <ArrowBackOutline color={'white'} onClick={arrowBackClick} style={{ cursor: 'pointer' }} />
                )}
                {isHomeMode && <IonIcon src={settingsSharp} id="settings-icon" onClick={onSettingsClick} />}
            </IonToolbar>
        </IonHeader>
    );
};

export default TitleBar;
