import React from 'react';
import { IonList } from '@ionic/react';
import { BillablesListItem } from './index';
import { BillDefinition } from '../lib/dataTypes/BillDefinition';
import '../theme/components/billablesCatalog.css';

interface Props {
    data: BillDefinition[];
    onItemClick: (item: BillDefinition) => void;
}

const BillablesList = ({ data, onItemClick }: Props) => {
    return (
        <IonList className="billables-catalog-list">
            {data.map((item: BillDefinition) => {
                return <BillablesListItem key={item.id} item={item} onItemClick={onItemClick} />;
            })}
        </IonList>
    );
};

export default BillablesList;
