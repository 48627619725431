import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import EtBillApiClient from '../../lib/EtBillApiClient';
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonLabel, useIonToast } from '@ionic/react';
import moment from 'moment-timezone';
import { selectReceipts, setReceipts } from '../../reducers/ReceiptsReducer';
import { ClientAvatar, ReceiptList } from '../index';
import { BillDetails as BillDetailsType } from '../../lib/dataTypes/BillDetails';
import Decimal from 'decimal.js';

const etBillApiClient = new EtBillApiClient();

interface Props {
    workspaceId: string;
    bill: BillDetailsType;
}

const DetailsUp = ({ bill, workspaceId }: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { receipts } = useAppSelector(selectReceipts);

    const [present] = useIonToast();
    const billId = bill.id;

    useEffect(() => {
        const getReceipts = async () => {
            const filter = { billId };
            const response = await etBillApiClient.getReceipts(filter, workspaceId);

            if (response.success) {
                dispatch(setReceipts(response.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
            }
        };

        getReceipts().then();
    }, [dispatch, billId]);

    const onReceiptSelected = (id: string) => {
        if (!id) {
            return;
        }
        history.push(`/receipt/${id}`);
    };

    const dueDate = moment(bill.dueDate).format('MM/DD/YYYY');
    const paid = new Decimal(bill.balance).eq(0);
    const overdue = moment(bill.dueDate).isBefore(moment.now()) && !paid;
    const overdueClassName = overdue ? 'overdue' : '';

    return (
        <IonCard className="ion-card">
            {bill && (
                <IonCardContent>
                    <IonGrid className="pay-details">
                        <IonRow>
                            <ClientAvatar loadingClient={false} data={bill?.client} />
                        </IonRow>
                        <IonRow className="separation">
                            <IonCol className="title-column title-pay">Pago por realizar</IonCol>
                            <IonCol className={`data-column ${overdueClassName}`}>Vencimiento: {dueDate}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="title-column">{bill.name}</IonCol>
                            <IonCol className={`data-column ${overdueClassName}`}>${bill.total}</IonCol>
                        </IonRow>
                        <IonRow className="separation">
                            <IonCol />
                            <IonCol className={`data-column ${overdueClassName}`}>Por pagar ${bill.balance}</IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <IonLabel className="labels">Recibos</IonLabel>
                        </IonRow>
                        <ReceiptList data={receipts} onItemSelected={onReceiptSelected} />
                    </IonGrid>
                </IonCardContent>
            )}
        </IonCard>
    );
};

export default DetailsUp;
