import React from 'react';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import { BillDefinition } from '../lib/dataTypes/BillDefinition';
import moment from 'moment-timezone';
import '../theme/components/billablesCatalog.css';

interface Props {
    item: BillDefinition;
    onItemClick: (item: BillDefinition) => void;
}

const BillablesListItem = ({ item, onItemClick }: Props) => {
    return (
        <IonGrid
            className="billables-catalog-grid-list"
            id={item.id}
            onClick={() => {
                onItemClick(item);
            }}>
            <IonRow className="billables-catalog-row-list">
                <IonCol size="4" className="billables-catalog-col-list">
                    {item.name}
                </IonCol>
                <IonCol size="2" className="billables-catalog-col-list">
                    {item.billingDate ? moment(item.billingDate).format('MM/DD/YYYY') : ''}
                </IonCol>
                <IonCol size="3" className="billables-catalog-col-list">
                    {item.dueDate ? moment(item.dueDate).format('MM/DD/YYYY') : ''}
                </IonCol>
                <IonCol size="3" className="billables-catalog-col-list">
                    {item.categories?.[0]?.name}
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default BillablesListItem;
