import { Menu } from 'react-ionicons';
import React, { useEffect, useState } from 'react';
import { ClientAvatar } from '../components';
import { useParams } from 'react-router-dom';
import EtBillApiClient from '../lib/EtBillApiClient';
import { IonContent, IonIcon, IonItem, IonLabel, useIonToast } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setReceipt, selectReceipts } from '../reducers/ReceiptsReducer';
import moment from 'moment-timezone';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import Spinner from './Spinner';
import '../theme/components/receiptDetails.css';
import { CheckmarkCircle } from 'react-ionicons';

const etbillApiClient = new EtBillApiClient();

const ReceiptDetails = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const { receipt } = useAppSelector(selectReceipts);
    const { workspace } = useAppSelector(getWorkspace);
    const [loadingReceipt, setLoadingReceipt] = useState(false);
    const workspaceId = workspace?.id || '';

    const [present] = useIonToast();

    useEffect(() => {
        const getReceipt = async (id: string) => {
            setLoadingReceipt(true);
            const result = await etbillApiClient.getReceipt(id, workspaceId);
            if (result.success) {
                console.log(result.data);
                dispatch(setReceipt(result.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
            }
            setLoadingReceipt(false);
        };

        getReceipt(id).then();
    }, [id, dispatch]);

    if (loadingReceipt) {
        return (
            <IonContent>
                <div className="content loadSpinner">
                    <Spinner />
                </div>
            </IonContent>
        );
    }

    return (
        <>
            <div className="content">
                <ClientAvatar data={receipt?.bill.client} />
            </div>
            <IonContent>
                <div className="content">
                    <div>
                        <IonItem className="ion-item1">
                            <Menu color="black" />
                            <div>
                                <IonLabel>
                                    Pago Realizado
                                    <br />
                                    {receipt?.bill.name}
                                </IonLabel>
                            </div>
                            <div className="ion-item">
                                <IonLabel>Vencimiento : {moment(receipt?.bill.dueDate).format('MM-DD-YYYY')}</IonLabel>
                            </div>
                        </IonItem>
                    </div>
                    <div>
                        <div>
                            <IonItem className="ion-item1">
                                <IonLabel className="Receipt-Folio">
                                    <h3>
                                        <span>
                                            <CheckmarkCircle color="green" />
                                        </span>
                                    </h3>
                                    <h3>
                                        Fecha de Pago: <span> {moment(receipt?.date).format('MM-DD-YYYY')}</span>
                                    </h3>
                                    <h3>
                                        Folio: <span> {receipt?.receiptNumber}</span>
                                    </h3>
                                    <h3>
                                        Metodo de pago: <span>{receipt?.paymentmethod}</span>
                                    </h3>
                                    <h3>
                                        Monto Pagado: <span>$ {receipt?.amount}</span>
                                    </h3>
                                </IonLabel>
                            </IonItem>
                        </div>
                    </div>
                </div>
            </IonContent>
        </>
    );
};

export default ReceiptDetails;
