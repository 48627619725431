import React from 'react';
import { IonPage } from '@ionic/react';
import { Home, TitleBar } from '../components';
import { useHistory } from 'react-router-dom';

const HomePage = () => {
    const history = useHistory();
    return (
        <IonPage className="page-background-color">
            <TitleBar
                isPrincipalMode={true}
                isHomeMode={true}
                onSettingsClick={() => {
                    history.push('/admin');
                }}
            />
            <Home />
        </IonPage>
    );
};

export default HomePage;
