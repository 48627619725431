import React from 'react';
import { IonContent } from '@ionic/react';
import { AdminSettingsList } from '.';
import '../theme/components/adminSettings.css';
import { useHistory } from 'react-router-dom';

const AdminSettings = () => {
    const history = useHistory();
    const routes = [
        {
            title: 'Tipo de Departamento',
            url: '/admin/clientTypes',
            id: 'clienttypes',
        },
        {
            title: 'Descuentos',
            url: '/admin/discounts',
            id: 'discounts',
        },
        {
            title: 'Conceptos de Cobro',
            url: '/admin/billables',
            id: 'billables',
        },
        {
            title: 'Asignación de tipo de departamento',
            url: '/admin/clientTypes/assign',
            id: 'clienttypes-assing',
        },
        {
            title: 'Asignación de conceptos',
            url: '/admin/billables/assign',
            id: 'billables-assing',
        },
    ];

    const onRouteSelected = (url: string) => {
        history.push(url);
    };
    return (
        <IonContent>
            <div className="content">
                <AdminSettingsList routes={routes} onRouteSelected={onRouteSelected} />
            </div>
        </IonContent>
    );
};

export default AdminSettings;
