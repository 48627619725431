import React, { useEffect } from 'react';
import {
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonInfiniteScroll,
    IonItem,
    IonPage,
    IonRow,
    useIonToast,
} from '@ionic/react';
import { DetailsDown, DetailsUp } from './index';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import '../../theme/components/billDetails.css';
import { getWorkspace } from '../../reducers/WorkspaceReducer';
import { selectBills, setBill } from '../../reducers/BillsReducer';
import EtBillApiClient from '../../lib/EtBillApiClient';
import Decimal from 'decimal.js';

const etBillApiClient = new EtBillApiClient();

const BillDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { workspace } = useAppSelector(getWorkspace);

    const dispatch = useAppDispatch();
    const location = useLocation();
    const [present] = useIonToast();
    const { bill } = useAppSelector(selectBills);

    const workspaceId = workspace?.id || '';

    useEffect(() => {
        console.log('updating bill...');
        const getBill = async () => {
            if (!location.pathname.includes('bill/')) {
                console.log('Clearing bill...');
                setBill(undefined);
                return;
            }

            const response = await etBillApiClient.getBill(id, workspaceId);

            if (response.success) {
                dispatch(setBill(response.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
            }
        };

        getBill().then();

        return function () {
            console.log('Cleansing bill details...');
            dispatch(setBill(undefined));
        };
    }, [dispatch, location, id]);

    if (!bill) {
        return <IonContent />;
    }

    const balance = new Decimal(bill.balance);
    const showDetailsDown = !balance.eq('0');
    return (
        <>
            <IonContent>
                <DetailsUp bill={bill} workspaceId={workspaceId} />
            </IonContent>
            {showDetailsDown && (
                <IonFooter>
                    <DetailsDown bill={bill} workspaceId={workspaceId} />
                </IonFooter>
            )}
        </>
    );
};

export default BillDetails;
