import React from 'react';
import { IonLabel, IonItem } from '@ionic/react';
import { AdminSettingsRoute } from '../lib/dataTypes';
import '../theme/components/adminSettings.css';

interface Props {
    route: AdminSettingsRoute;
    onRouteSelected: (id: string) => void;
}

const AdminSettingsListItem = ({ route, onRouteSelected }: Props) => {
    return (
        <IonItem className="adminsettings-item" id={route.id}>
            <IonLabel
                className="adminsettings-label"
                onClick={() => {
                    onRouteSelected(route.url);
                }}>
                {route.title}
            </IonLabel>
        </IonItem>
    );
};

export default AdminSettingsListItem;
