import React from 'react';
import { IonPage } from '@ionic/react';
import { AdminSettings, TitleBar } from '../components';
import { useHistory } from 'react-router-dom';

const AdminSettingsPage = () => {
    const history = useHistory();
    return (
        <IonPage className="page-background-color">
            <TitleBar
                isBackMode={true}
                arrowBackClick={() => {
                    history.goBack();
                }}
            />
            <AdminSettings />
        </IonPage>
    );
};

export default AdminSettingsPage;
