import React, { useState, useEffect } from 'react';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRow,
    useIonToast,
    IonFooter,
} from '@ionic/react';
import { addSharp } from 'ionicons/icons';
import { BillablesList } from './index';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import EtBillApiClient from '../lib/EtBillApiClient';
import '../theme/components/billablesCatalog.css';
import { BillDefinition } from '../lib/dataTypes/BillDefinition';
import { BillType } from '../lib/dataTypes';

const BillablesCatalog = () => {
    const history = useHistory();
    const [billDefinitions, setBillDefinitions] = useState<BillDefinition[]>([]);
    const { workspace } = useAppSelector(getWorkspace);
    const workspaceId = workspace?.id || '';
    const [present] = useIonToast();
    useEffect(() => {
        const apiClient = new EtBillApiClient();
        const load = async () => {
            const result = await apiClient.getBillDefinitions(workspaceId);
            if (result.success) {
                setBillDefinitions(result.data);
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
            }
        };

        load().then();
    }, [workspaceId]);

    const onItemClick = (item: BillDefinition) => {
        history.push(`/admin/billables/${item.id}`);
    };

    const addBill = () => {
        history.push('/admin/billables/create');
    };

    return (
        <IonContent>
            <div className="content">
                <IonLabel className="billables-catalog-title">Conceptos de Cobro</IonLabel>
                <IonGrid>
                    <IonRow className="billables-catalog-row-title">
                        <IonCol size="4" className="billables-catalog-col-title">
                            Descripión
                        </IonCol>
                        <IonCol size="2" className="billables-catalog-col-title">
                            Inicio
                        </IonCol>
                        <IonCol size="3" className="billables-catalog-col-title">
                            Vencimiento
                        </IonCol>
                        <IonCol size="3" className="billables-catalog-col-title">
                            Categoría
                        </IonCol>
                    </IonRow>
                </IonGrid>
                {billDefinitions && billDefinitions.length ? (
                    <BillablesList data={billDefinitions} onItemClick={onItemClick} />
                ) : (
                    <h6 className="noBillables">No tienes movimientos</h6>
                )}
                <IonFooter className="billables-catalog-footer">
                    <IonButton onClick={addBill} className="add-bill-button">
                        <IonIcon icon={addSharp} className="add-bill-button-icon" />
                        Agregar nuevo
                    </IonButton>
                </IonFooter>
            </div>
        </IonContent>
    );
};

export default BillablesCatalog;
