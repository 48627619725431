import React, { useEffect, useState } from 'react';
import { Bill } from '../lib/dataTypes';
import BillDefinitionSelector from './BillDefinitionSelector';
import { BillDefinition } from '../lib/dataTypes/BillDefinition';
import { IonButton, IonFooter, IonIcon, IonInput, IonItem, IonLabel, useIonToast } from '@ionic/react';
import EtBillApiClient from '../lib/EtBillApiClient';
import { BillDefinitionDetails } from '../lib/dataTypes/BillDefinitionDetails';
import { receipt, reorderFour } from 'ionicons/icons';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import { useAppSelector } from '../app/hooks';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import { ReorderFour } from 'react-ionicons';

interface Props {
    clientId: string;
    onDismiss: () => void;
}

const apiClient = new EtBillApiClient();
const NewBillForm = ({ clientId, onDismiss }: Props) => {
    const history = useHistory();
    const [isWorking, setIsWorking] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [billDefinition, setBillDefinition] = useState<BillDefinition>();
    const [billDefinitionDetails, setBillDefinitionDetails] = useState<BillDefinitionDetails>();
    const [year, setYear] = useState(moment().year().toString());
    const buttonsAreDisabled = isWorking || !billDefinitionDetails;

    const { workspace } = useAppSelector(getWorkspace);
    const workspaceId = workspace?.id || '';

    const [present] = useIonToast();
    useEffect(() => {
        const loadDetails = async () => {
            const id = billDefinition?.id;
            if (!id) {
                return;
            }

            const response = await apiClient.getBillDefinition(id, workspaceId);
            if (response.success) {
                setBillDefinitionDetails(response.data);
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
                setErrorMessage(response.message);
            }
        };

        loadDetails().then();
    }, [billDefinition]);

    const onCreateClick = async (shouldNavigate: boolean) => {
        if (!billDefinitionDetails) {
            return;
        }

        const data = {
            clientId,
            definitionId: billDefinitionDetails.id,
            year,
        };

        setIsWorking(true);
        const result = await apiClient.createBill(data, workspaceId);
        setIsWorking(false);
        if (!result.success) {
            present({
                color: 'danger',
                position: 'top',
                message: 'Ocurrio un Error al obtener informacion',
                duration: 2000,
            });
            // Treat failure and session error the same way
            setErrorMessage(result.message);
            return;
        }

        if (shouldNavigate) {
            history.push(`/bill/${result.data.id}`);
            onDismiss();
        } else {
            setBillDefinitionDetails(undefined);
            setBillDefinition(undefined);
        }
    };

    function renderContent() {
        if (errorMessage) {
            return <div>{errorMessage}</div>;
        }

        if (!billDefinitionDetails) {
            return <></>;
        }

        const billingDate = billDefinitionDetails.billingDate.split(' ')[0];

        const dueDate = billDefinitionDetails.dueDate.split(' ')[0];

        return (
            <>
                <IonItem>
                    <IonIcon icon={reorderFour} size="large" style={{ color: 'black' }} />
                    <IonLabel>{billDefinitionDetails.name}</IonLabel>
                    <IonLabel style={{ marginLeft: '40px' }}>{billDefinitionDetails.behaviors[0].amount}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Año: </IonLabel>
                    <IonInput type="number" value={year} onIonChange={(e) => setYear(e.detail.value || '')} />
                </IonItem>
                <IonItem>
                    <IonLabel>Corte: </IonLabel>
                    <IonLabel>{billingDate}</IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel>Límite: </IonLabel>
                    <IonLabel>{dueDate}</IonLabel>
                </IonItem>
            </>
        );
    }

    return (
        <div style={{ display: 'flex', flexFlow: 'column', flex: 1 }}>
            <IonItem>
                <BillDefinitionSelector
                    value={billDefinition}
                    onItemSelected={(item) => {
                        console.log('New definition selected: ', item?.name);
                        setBillDefinition(item);
                    }}
                />
            </IonItem>
            <div style={{ flex: 1 }}>{renderContent()}</div>
            <IonFooter>
                <IonButton disabled={buttonsAreDisabled} onClick={() => onCreateClick(false)}>
                    Asignar
                </IonButton>
                <IonButton disabled={buttonsAreDisabled} onClick={() => onCreateClick(true)}>
                    Asignar y abrir
                </IonButton>
            </IonFooter>
        </div>
    );
};

export default NewBillForm;
