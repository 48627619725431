import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Bill } from '../lib/dataTypes';
import { BillDetails } from '../lib/dataTypes/BillDetails';

export interface BillsState {
    bills: Bill[];
    bill: BillDetails | undefined;
}

const initialState: BillsState = {
    bills: [],
    bill: undefined,
};

export const BillSlice = createSlice({
    name: 'bills',
    initialState,
    reducers: {
        setBills: (state, action: PayloadAction<Bill[]>) => {
            Object.assign(state, {
                bills: action.payload,
            });
        },
        setBill: (state, action: PayloadAction<Bill | undefined>) => {
            Object.assign(state, {
                bill: action.payload,
            });
        },
    },
});

export const { setBills, setBill } = BillSlice.actions;

export const selectBills = (state: RootState) => state.bills;

export default BillSlice.reducer;
